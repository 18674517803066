export const initialState = {
    data: {},
    loader: true,
    menuitem: true
};

export function interview(state = initialState, action) {
    switch (action.type) {
        case 'GET_INTERVIEW':
            return {
                ...state,
                data: action.payload,
                loader: false,
                menuitem: true
            };

        case 'GET_INTERVIEW_FAILURE':
            return {
                ...state,
                error: action.error,
                loader: false,
                menuitem: false
            };

        default:
            return state
    }

}