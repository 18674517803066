export const initialState = {
    data: {},
    loader: true,
    menuitem: true
};



export function tours(state = initialState, action) {
    switch (action.type) {
        case 'GET_TOURS':
            return {
                ...state,
                data: action.payload,
                loader: false,
                menuitem: true
            };

        case 'GET_TOURS_FAILURE':
            return {
                ...state,
                error: action.error,
                loader: false,
                menuitem: false
            };

        default:
            return state
    }

}