//Global
export const WAITING_FOR_LOAD = 'Ждем подгрузки блока';
export const INSTAGRAMM_LINK = 'https://www.instagram.com/katja_karling/';
export const FACEBOOK_LINK = 'https://www.facebook.com/katja.karling';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UC0nLFVLEi8IBr_dlUBIvKmQ';
export const ADMIN_EMAIL = 'katja_karling@hotmail.com';

//Navigation
export const ABOUT_MENU_ITEM = 'Обо мне';
export const INTERVIEW_MENU_ITEM = 'Интервью и публикации';
export const EVENTS_MENU_ITEM = 'События';
export const TOURS_MENU_ITEM = 'Дизайн-туры';
export const ABOUT_MENU_LINK = '#aboutme';
export const INTERVIEW_MENU_LINK = '#interview';
export const EVENTS_MENU_LINK = '#events';
export const TOURS_MENU_LINK = '#tours';

//Hello
export const CONNECT = 'Связаться';

//About me
export const ABOUT_ME_TITLE = 'Обо мне';
export const PARTNERS_TITLE = 'Партнёры';

//Photos
export const PHOTOS_TITLE = 'Как проходят мои лекции';
export const WAITING_FOR_PHOTOS_LOAD = 'Ждем подгрузки фотографий';

//Interview
export const INTERVIEW_TITLE = 'Интервью и публикации';
export const WAITING_FOR_INTERVIEW_LOAD = 'Ждем подгрузки Интервью и Публикаций';
export const WAITING_FOR_PUBLICATIONS_LOAD = 'Ждем подгрузки Публикаций';
export const CONNECT_WITH_ME = 'Связаться со мной';

//Events
export const EVENTS_TITLE = 'События';
export const WAITING_FOR_EVENTS_LOAD = 'Ждем подгрузки событий';
export const PAST_EVENTS_TITLE = 'Прошедшие события';
export const NO_EVENTS = 'К сожалению, событий не найдено';

//Tours
export const TOURS_TITLE = 'Дизайн туры';
export const WAITING_FOR_TOURS_LOAD = 'Ждем подгрузки туров';
export const LEARNMORE = 'Узнать больше';
export const SEND_EMAIL_TITLE = 'Узнать больше';
export const MESSAGE_SENT = 'Ваш запрос успешно отправлен, я свяжусь с Вами в ближайшее время.';
export const SEND_BUTTON = 'Отправить';
export const YOUR_NAME = 'Ваше Имя';
export const YOUR_EMAIL = 'Ваш Email';

//Footer
export const WAITING_FOR_FOOTER_LOAD = 'Ждем подгрузки футера';
export const DESIGNED_IN = 'Разработано в';
export const CHG = 'Cherry Garden';
export const WRITE_TO_ME = 'Написать мне';
export const THANK_YOU_MESSAGE = 'Спасибо, что подписались!';
export const WAITING_MESSAGE = 'Ожидаем отправки данных, пожалуйста, подождите.';
export const SUBSCRIBE = 'Подписаться';
export const SUBSCRIBE_PLACEHOLDER = 'Ваша электронная почта';