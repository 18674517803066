export const initialState = {
    data: {},
    loader: true
};



export function navigation(state = initialState, action) {
    switch (action.type) {
        case 'GET_NAVIGATION':
            return {
                ...state,
                data: action.payload,
                loader: false
            };

        case 'GET_NAVIGATION_FAILURE':
            return {
                ...state,
                error: action.error,
                loader: false
            };

        default:
            return state
    }

}