import * as t from '../actionTypes/';


//main Action Creator, that is taking info from WP
export const getInfo = (url, type_success, type_failure) => {
    
    return dispatch => {

        return fetch(url)
            .then(
                response => response.json())
            .then( (json) => {
                if (!json.data) {
                    dispatch({
                        type: type_success,
                        payload: json
                    })
                } else {
                    dispatch({
                        type: type_failure,
                        payload: json
                    })
                }
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: type_failure,
                    payload: error
                })
            });
    }

};

//Action Creator for Subscribe form
export const fetchSubscribeForm = (values) => {

    return dispatch => {

        dispatch ({
            type: t.SEND_EMAIL_WAITING,
        });

        const link = new URL('https://cors-anywhere.herokuapp.com/https://us19.api.mailchimp.com/2.0/lists/subscribe.json');

        const params = {
            'apikey': 'd8110b64d0560f62419abb7ac01f54fc-us19',
            'id': '18ec457a4e',
            'email[email]': values.email,
            'double_optin': 'false',
            'send_welcome': 'false'
        };

        link.search = new URLSearchParams(params);

        return fetch(link)
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: t.SEND_EMAIL_SUCCESS,
                    })
                } else {
                    dispatch({
                        type: t.SEND_EMAIL_ERROR,
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });

    }
};


//Action Creator for Tours form
export const fetchToursForm = (values) => {

    return dispatch => {
        dispatch({
            type: t.SEND_TOURS_WAITING,
        });

        const link = new URL('https://cors-anywhere.herokuapp.com/https://docs.google.com/forms/d/1Vi092bmpEZDv-FAtRWFTj0AT-LLRbiw7gqZ_7CbroYM/formResponse');

        const params = {
            'entry.51962374': values.email,
            'entry.546374500': values.name,
            'entry.2110778378': values.title
        };

        link.search = new URLSearchParams(params);

        return fetch(link)
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: t.SEND_TOURS_SUCCESS,
                    })
                } else {
                    dispatch({
                        type: t.SEND_TOURS_ERROR,
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });

    }
};