import React from 'react';
import { connect } from 'react-redux';
import { scrollSpy, Link as NavLink} from 'react-scroll';
import { Route, Switch, Link, withRouter } from 'react-router-dom';
import Logo from '../img/logo.svg';
import InstaIcon from '../img/insta-icon.svg';
import FbIcon from '../img/fb-icon.svg';
import YoutIcon from '../img/yout-icon.svg';
import '../css/navigation.scss';
import * as t from '../constants/';

//Container for navigation panel
class Navigation extends React.Component {
    componentDidMount() {

        //Scrolling to hash on homepage open
        if(window.location.hash) {
            if(document.querySelector(window.location.hash)) {
                const y = document.querySelector(window.location.hash).getBoundingClientRect().top + window.scrollY;
                window.scroll({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }

        //Scrolling spy for top menu
        scrollSpy.update();

        //setting header fixed on scrolling
        let scrollpos = window.scrollY;
        let header = document.querySelector("nav");

        let add_class_on_scroll = () => {
            header.classList.add("fixed");
        };

        let remove_class_on_scroll = () => {
            header.classList.remove("fixed");
        };

        window.addEventListener('scroll', () => {
            scrollpos = window.scrollY;

            if(scrollpos > 60){
                add_class_on_scroll();
            } else {
                remove_class_on_scroll();
            }
        });
    };

    render(){

        //open-close menu
        const mobileMenuOpen = () => {
            const btn = document.querySelector('#header__button');
            const mobMenu = document.querySelector('.menu');
            const topIcons = document.querySelector('.top-icons');

            btn.classList.toggle('hamburger--open');
            mobMenu.classList.toggle('opened');
            topIcons.classList.toggle('opened');
        };

        const menuItemsArray =[
            {
                id: 1,
                link: t.ABOUT_MENU_LINK,
                title: t.ABOUT_MENU_ITEM,
                menuitem: this.props.about.menuitem
            },
            {
                id: 2,
                link: t.INTERVIEW_MENU_LINK,
                title: t.INTERVIEW_MENU_ITEM,
                menuitem: this.props.interview.menuitem
            },
            {
                id: 3,
                link: t.EVENTS_MENU_LINK,
                title: t.EVENTS_MENU_ITEM,
                menuitem: this.props.events.menuitem
            },
            {
                id: 4,
                link: t.TOURS_MENU_LINK,
                title: t.TOURS_MENU_ITEM,
                menuitem: this.props.tours.menuitem
            },
        ];

        const menuItems = menuItemsArray.map(item => {

            const innerPagesLink = '/' + item.link;

            if (item.menuitem) {
                return (
                    <Switch key={item.id}>
                        <Route exact path="/" render={() => (
                            <li>
                                <NavLink activeClass="active" to={item.link} spy={true} smooth={true} offset={-60} duration={500}>
                                    {item.title}
                                </NavLink>
                            </li>
                        )}/>

                        <Route render={() => (
                            <li>
                                <Link to={innerPagesLink}>
                                    {item.title}
                                </Link>
                            </li>
                        )}/>
                    </Switch>
                )
            } else {
                return '';
            }
        });

        return (
            <nav>
                <div className="container">

                    <Switch>
                        <Route exact path="/" render={() => (
                            <div>
                                <img src={Logo} className="logo" alt="" />
                                <ul className="top-icons">
                                    <li><a href={t.INSTAGRAMM_LINK} target="_blank" rel="noopener noreferrer"><img src={InstaIcon} className="insta-icon" alt="" /></a></li>
                                    <li><a href={t.FACEBOOK_LINK} target="_blank" rel="noopener noreferrer"><img src={FbIcon} className="insta-icon" alt="" /></a></li>
                                    <li><a href={t.YOUTUBE_LINK} target="_blank" rel="noopener noreferrer"><img src={YoutIcon} className="insta-icon" alt="" /></a></li>
                                </ul>

                                <ul className="menu">
                                    {menuItems}
                                </ul>
                            </div>
                        )}/>
                        <Route render={() => (
                            <div>
                                <Link to="/">
                                    <img src={Logo} className="logo innerlogo" alt="" />
                                </Link>
                                <ul className="top-icons innertop-icons">
                                    <li><a href={t.INSTAGRAMM_LINK} target="_blank" rel="noopener noreferrer"><img src={InstaIcon} className="insta-icon" alt="" /></a></li>
                                    <li><a href={t.FACEBOOK_LINK} target="_blank" rel="noopener noreferrer"><img src={FbIcon} className="insta-icon" alt="" /></a></li>
                                    <li><a href={t.YOUTUBE_LINK} target="_blank" rel="noopener noreferrer"><img src={YoutIcon} className="insta-icon" alt="" /></a></li>
                                </ul>

                                <ul className="menu innermenu">
                                    {menuItems}
                                </ul>
                            </div>
                        )}/>
                    </Switch>

                    <div id="header__button" className="hamburger" onClick={() => {mobileMenuOpen()}}>
                        <div className="hamburger__inner" />
                    </div>
                    <div className="clear" />
                </div>
            </nav>
        )
    }
}


const mapStateToProps = store => {
    return {
        about: store.about,
        interview: store.interview,
        events: store.events,
        tours: store.tours,
    };
};


export default withRouter(connect(
    mapStateToProps
)(Navigation))