import React from 'react';
import Slider from "react-slick";
import PropTypes from 'prop-types';
import {ToursForm} from '../components/ToursForm';
import CloseIco from '../img/close-ico.svg';
import * as t from '../constants/';

//component that is showing each Tour in Tours section
class Tour extends React.Component {

    handleSubmit = (values) => {
        this.props.fetchToursForm(values);
    };

    render () {

        const {tourBg, tourGallery, tourDays, tourMonth, tourLogo, tourIcons, tourAnnouncement, tourMinilogo, tourLink, tourContent, tourId, tourTitle} = this.props;

        //adding background for tour
        const TourInlineStyle = {
            backgroundImage: `url(${tourBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        };

        const curId = 'tour-' + this._reactInternalFiber.key;

        //opening block with tour description
        const openDesc = () => {
           document.querySelector('#' + curId + ' .tour-desc').style.display = 'block';

            const y = document.querySelector('#' + curId + ' .tour-desc').getBoundingClientRect().top + window.scrollY - 60;
            window.scroll({
                top: y,
                behavior: 'smooth'
            });
        };

        //closing block with tour description
        const closeDesc = () => {
            document.querySelector('#' + curId + ' .tour-desc').style.display = 'none';
        };

        //slick Slider
        const settings = {
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            dots: true,
            adaptiveHeight: true
        };

        //showing images from tour's gallery
        let curGal;
        if (tourGallery) {
            curGal = tourGallery.map( (item) => {
                return (
                    <img src={item.sizes.medium_large} key={item.id} alt="" />
                )
            });
        }

        //generating form ID
        const formId= 'tour' + tourId;

        //sending initisl values to form
        const getInitialValues = () => {
            return {
                title: tourTitle
            };
        };

        return (

            <div className="tour-wrap" id={curId}>
                <div className="tour" style={TourInlineStyle}>
                    <div className="container">
                        <div className="colls-2 leftcol">
                            <div className="tour-date">
                                <span className="date-num">{tourDays}</span> {tourMonth}
                            </div>

                            <div className="tour-logo">
                                <img src={tourLogo} alt="" />
                            </div>

                            <div className="white-btn" onClick={openDesc}>
                                {t.LEARNMORE}
                            </div>

                        </div>
                        <div className="colls-2">
                            <div className="tour-icos" dangerouslySetInnerHTML={{__html: tourIcons}} />
                            <div className="tour-minidesc" dangerouslySetInnerHTML={{__html: tourAnnouncement}} />
                        </div>
                        <div className="clear" />
                    </div>
                </div>

                <div className="tour-desc">
                    <img src={CloseIco} className="close-tour" onClick={closeDesc} alt="" />

                        <div className="container">
                            <div className="colls-2">
                                <img src={tourMinilogo} className="tour-desc-logo" alt="" />

                                    <div className="tour-desc-dates">
                                        {tourDays} {tourMonth}
                                    </div>
                                    <div className="clear" />

                                    <a href={tourLink} target="blank" className="tour-desc-link">
                                        {tourLink}
                                    </a>
                            </div>
                            <div className="colls-2" />
                            <div className="clear" />

                            <div className="colls-2" dangerouslySetInnerHTML={{__html: tourContent}} />
                            <div className="colls-2">
                                <Slider {...settings}>
                                    {curGal}
                                </Slider>
                            </div>
                            <div className="clear" />

                            <div className="colls-1">
                                <div className="form-head">
                                    {t.SEND_EMAIL_TITLE}
                                </div>

                                <ToursForm form={formId} initialValues={getInitialValues()} onSubmit={this.handleSubmit} />

                            </div>
                        </div>
                </div>
            </div>

        )
    }
}

export {Tour}

Tour.propTypes = {
    tourBg: PropTypes.string.isRequired,
    tourGallery: PropTypes.array.isRequired,
    tourDays: PropTypes.string.isRequired,
    tourMonth: PropTypes.string.isRequired,
    tourLogo: PropTypes.string.isRequired,
    tourIcons: PropTypes.string.isRequired,
    tourAnnouncement: PropTypes.string.isRequired,
    tourMinilogo: PropTypes.string.isRequired,
    tourLink: PropTypes.string.isRequired,
    tourContent: PropTypes.string.isRequired,
    tourId: PropTypes.number.isRequired,
    tourTitle: PropTypes.string.isRequired
};