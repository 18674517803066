import React from 'react';
import PastEventsList from "../containers/PastEventsList";

//Component for Past Events Page
const PastEvents = () => {

    //scroll to top
    window.scrollTo(0, 0);

    return(
        <React.Fragment>
            <PastEventsList />
        </React.Fragment>
    )
};

export default PastEvents;