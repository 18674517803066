import React , {Component} from 'react';
import { Field, reduxForm} from 'redux-form';
import { required, email } from 'redux-form-validators';

import * as t from '../constants';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="submissionInput">
            <input {...input} placeholder={label} type={type}/>
            {touched && error && <span>{error}</span>}
    </div>
);

//Component with Subscribtion form in Footer
class SubscribeForm extends Component {
    render(){

        const {handleSubmit} = this.props;

        let SubscribeFormTemplate;

        if (this.props.success === true) {
            SubscribeFormTemplate = <div style={{color: '#fff', textAlign: 'right', margin: '40px 0'}}>{t.THANK_YOU_MESSAGE}</div>;
        } else if (this.props.waiting === true) {
            SubscribeFormTemplate = <div style={{color: '#fff', textAlign: 'right', margin: '40px 0'}}>{t.WAITING_MESSAGE}</div>;
        } else {
            SubscribeFormTemplate =  <form onSubmit={handleSubmit}>
                <div className="tab">
                    <div className="cell cell1">
                        <Field
                            name="email"
                            component={renderField}
                            validate={[required(), email()]}
                            type="email"
                            label={t.SUBSCRIBE_PLACEHOLDER}
                        />
                    </div>
                    <div className="cell cell2">
                        <button type="submit" className="white-btn">{t.SUBSCRIBE}</button>
                    </div>
                </div>

                <div className="clear" />
            </form>
        }

        return SubscribeFormTemplate;
    }
}

SubscribeForm = reduxForm({
    form: 'subscribe',
})(SubscribeForm);

export {SubscribeForm}