import React , {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import Validators, { required, email } from 'redux-form-validators';
import * as t from '../constants/';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="tourFormInputWrap">
            <input {...input} placeholder={label} type={type}/>
            {touched && error && <span>{error}</span>}
    </div>
);

//Component with form for Tour
class ToursForm extends Component {
    render(){

        const {handleSubmit} = this.props;

        let ToursFormTemplate;

        if(this.props.submitSucceeded === true) {
            ToursFormTemplate = <div style={{margin: '30px 0'}}>{t.MESSAGE_SENT}</div>
        } else {
            ToursFormTemplate = <form onSubmit={handleSubmit}>
                <div className="tab">
                    <div className="cell cell1">
                        <Field
                            name="name"
                            component={renderField}
                            validate={[required()]}
                            type="text"
                            label={t.YOUR_NAME}
                        />
                        <Field
                            name="email"
                            component={renderField}
                            validate={[required(), email()]}
                            type="email"
                            label={t.YOUR_EMAIL}
                        />
                    </div>
                    <div className="cell cell2">
                        <button type="submit" className="tourFormSubmit">{t.SEND_BUTTON}</button>
                    </div>
                </div>
                <div className="clear" />
            </form>
        }

        return ToursFormTemplate;
    }
}


ToursForm = reduxForm()(ToursForm);

Object.assign(Validators.messages, {
    email:    "Внесите корректный email.",
    presence: "Заполните, пожалуйста, поле.",
});

export {ToursForm}