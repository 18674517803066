import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Navigation from "../containers/Navigation";
import Home from "../components/HomePage";
import PastEvents from "../components/PastEventsPage";
import NoMatch from "../components/NoMatch";
import Footer from "../containers/Footer";

import '../css/main.scss';

const App = () => {

    return(
        <React.Fragment>
            <Route component={props => <Navigation {...props} />} />

            <Route
                render={({ location }) => (
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            classNames="fade"
                            timeout={300}
                        >
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route path='/past_events' component={PastEvents} />
                                <Route component={NoMatch} />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                )} />


            <Route component={Footer} />
        </React.Fragment>
    )
};

export default App;