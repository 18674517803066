export const GET_NAVIGATION = 'GET_NAVIGATION';
export const GET_NAVIGATION_FAILURE = 'GET_NAVIGATION_FAILURE';

export const GET_HELLO = 'GET_HELLO';
export const GET_HELLO_FAILURE = 'GET_HELLO_FAILURE';

export const GET_ABOUT = 'GET_ABOUT';
export const GET_ABOUT_FAILURE = 'GET_ABOUT_FAILURE';

export const GET_PHOTOS = 'GET_PHOTOS';
export const GET_PHOTOS_FAILURE = 'GET_PHOTOS_FAILURE';

export const GET_INTERVIEW = 'GET_INTERVIEW';
export const GET_INTERVIEW_FAILURE = 'GET_INTERVIEW_FAILURE';

export const GET_PUBLICATIONS = 'GET_PUBLICATIONS';
export const GET_PUBLICATIONS_FAILURE = 'GET_PUBLICATIONS_FAILURE';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const SEND_TOURS_WAITING = 'SEND_TOURS_WAITING';
export const SEND_TOURS_SUCCESS = 'SEND_TOURS_SUCCESS';
export const SEND_TOURS_ERROR = 'SEND_TOURS_ERROR';

export const GET_TOURS = 'GET_TOURS';
export const GET_TOURS_FAILURE = 'GET_TOURS_FAILURE';

export const SEND_EMAIL_WAITING = 'SEND_EMAIL_WAITING';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';

export const GET_FOOTER = 'GET_FOOTER';
export const GET_FOOTER_FAILURE = 'GET_FOOTER_FAILURE';