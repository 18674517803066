export const initialState = {
    data: {},
    loader: true
};



export function footer(state = initialState, action) {
    switch (action.type) {
        case 'GET_FOOTER':
            return {
                ...state,
                data: action.payload,
                loader: false
            };

        case 'GET_FOOTER_FAILURE':
            return {
                ...state,
                error: action.error,
                loader: false
            };

        case 'SEND_EMAIL_WAITING':
            return {
                ...state,
                formWaiting: true
            };

        case 'SEND_EMAIL_SUCCESS':
            return {
                ...state,
                formSent: true
            };

        case 'SEND_EMAIL_ERROR':
            return state;

        default:
            return state
    }

}