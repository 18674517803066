import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getInfo} from '../actions/';
import '../css/about.scss';
import * as t from '../constants/';

//container for About me section
export class About extends React.Component {
    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/pages/152', 'GET_ABOUT', 'GET_ABOUT_FAILURE');
    }

    render(){
        const {data, loader} = this.props;

        let aboutTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            aboutTemplate =  <section id="aboutme" name="#aboutme">
                <div className="container">
                    <h1>{t.ABOUT_ME_TITLE}</h1>
                <p style={{textColor: '#fff'}}>{t.WAITING_FOR_LOAD}</p>
                </div>
            </section>

        } else if (data.acf) {

            const partners = data.acf.about_partners.map ((item) => {
                return (<img src={item.url} key={item.id} alt="" />);
            });

            aboutTemplate = <section id="aboutme" name="#aboutme">
                <div className="container">
                    <h1>{t.ABOUT_ME_TITLE}</h1>

                    { data.content.rendered ? <div className="leftblock" dangerouslySetInnerHTML={{__html: data.content.rendered}} /> : null }
                    { data.acf.about_icons ? <div className="rightblock" dangerouslySetInnerHTML={{__html: data.acf.about_icons}} /> : null }
                    <div className="clear" />

                    { data.acf.about_partners.length !== 0 ?
                        <div className="colls-1">
                            <div className="partners">
                                <h4>
                                    {t.PARTNERS_TITLE}
                                </h4>

                                <div className="text-center">
                                    {partners}
                                </div>
                            </div>
                        </div>
                        : null}

                </div>
            </section>

        } else {

            aboutTemplate = '';

        }

        return aboutTemplate;

    }
}


const mapStateToProps = store => {
    return store.about;
};


const mapDispatchToProps = dispatch => {
    return {
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(About)

About.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};