import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {PastEventsFilter} from '../components/PastEventsFilter';
import {Event} from '../components/Event';
import {getInfo} from '../actions/';
import '../css/events.scss';
import * as t from '../constants/';

//container for Events section
class PastEventsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: []
        }
    }


    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/posts?categories=2', 'GET_EVENTS', 'GET_EVENTS_FAILURE');
    }

    componentWillReceiveProps(nextProps) {
        //add filtered data to State, for sorting it
        this.setState({
            events: nextProps.data.data.filter( item => {return (new Date() > new Date(item.acf.event_date)) ? item : null;})
        });
    }

    render() {
        const {data, loader} = this.props.data;

        //Filter data by date to show only passed events
        let pastEventsArray =[];
        if (data.length > 0) {
            pastEventsArray = data.filter( item => {
                return (new Date() > new Date(item.acf.event_date)) ? item : null;
            });
        }

        //Sorting events
        const pastEventsArraySorting = year => {
            const pastEventsArraySorted = pastEventsArray.filter( item => {
                return Number(item.acf.event_date.split(' ', 1)) === Number(year);
            });

            this.setState({
                events: pastEventsArraySorted
            });
        };

        let eventsTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            eventsTemplate = <section id="events" className="pastevents">
                <div className="container">
                <h1>{t.PAST_EVENTS_TITLE}</h1>
                <div className="clear" />
                <h3>{t.WAITING_FOR_EVENTS_LOAD}</h3>
                <div className="clear" />
                </div>
            </section>

        } else if (this.state.events.length > 0) {  //if elements count is more, than 0: showing them

            eventsTemplate = <section id="events" className="pastevents">
                <div className="container">
                <h1>{t.PAST_EVENTS_TITLE}</h1>
                <div className="clear" />

                <PastEventsFilter sortEventsList={pastEventsArraySorting} eventsList={pastEventsArray} />

                { this.state.events.map( (item, i) => {
                    return (
                        <Event
                            eventDate={item.acf.event_date}
                            eventCity={item.acf.event_city}
                            eventPhoto={item.acf.event_photo}
                            eventTitle={item.title.rendered}
                            eventContent={item.content.rendered}
                            eventAddress={item.acf.event_address}
                            eventTime={item.acf.event_time}
                            key={item.id}
                            eventNum={i}
                        />
                    )
                }) }
                <div className="clear" />
                </div>
            </section>

        } else { //otherwise show "There is no elements"

            eventsTemplate = <section id="events" className="pastevents">
                <div className="container">
                    <h1>{t.PAST_EVENTS_TITLE}</h1>
                    <div className="clear" />
                    <h3>{t.NO_EVENTS}</h3>
                    <div className="clear" />
                </div>
            </section>

        }

        return eventsTemplate;

    }
}


const mapStateToProps = store => {
    return {
        data: store.events,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PastEventsList)

PastEventsList.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};