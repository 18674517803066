import React from 'react';
import PropTypes from 'prop-types';

//Component, that is showing each Event from Events section
class Event extends React.Component {
    render () {

        const {eventDate, eventCity, eventPhoto, eventTitle, eventContent, eventAddress, eventTime, eventNum} = this.props;

        //working with date
        //day
        const getDateDay = (data) => {
            if (data !== null) {
                const dateArray = data.split(' ', 3);
                return dateArray[2];
            }
        };

        const CurrentDay = getDateDay(eventDate);

        //month
        const getDateMonth = (data) => {
            if (data !== null) {
                const dateArray = data.split(' ', 3);

                switch (dateArray[1]) {
                    case '01': return 'Января';
                    case '02': return 'Февраля';
                    case '03': return 'Марта';
                    case '04': return 'Апреля';
                    case '05': return 'Мая';
                    case '06': return 'Июня';
                    case '07': return 'Июля';
                    case '08': return 'Августа';
                    case '09': return 'Сентября';
                    case '10': return 'Октября';
                    case '11': return 'Ноября';
                    case '12': return 'Декабря';
                    default: return ''
                }

            } else {
                return '';
            }
        };

        const CurrentMonth = getDateMonth(eventDate);

        //adding after each 2 elements <div class="clear"></div>
        const getClear = (data) => {
            if (data !== null) {
                if(data%2) {
                    return true;
                }
            }
        };

        const CurrentClear = getClear(eventNum);

        return (
            <React.Fragment>
                <div className="colls-2">
                    <div className="event-header">
                        {eventDate ? <div className="event-date"><span className="date-num">{CurrentDay}</span> {CurrentMonth}</div> : null}

                        {eventCity ? <div className="event-place">{eventCity}</div> : null}
                        <div className="clear" />
                    </div>

                    {eventPhoto ? <div className="event-img"><img src={eventPhoto} alt={eventTitle} /></div> : null}

                    {eventTitle ? <div className="event-title">{eventTitle}</div> : null}

                    {eventContent ? <div className="event-desc" dangerouslySetInnerHTML={{__html: eventContent}} /> : null}

                    {eventAddress ? <div className="event-address">{eventAddress}</div> : null}

                    {eventTime ? <div className="event-time">{eventTime}</div> : null}
                    <div className="clear" />
                </div>

                {CurrentClear === true ? <div className="clear" /> :''}
            </React.Fragment>
        )
    }
}

export {Event}

Event.propTypes = {
    eventDate: PropTypes.string.isRequired,
    eventCity: PropTypes.string.isRequired,
    eventPhoto: PropTypes.string.isRequired,
    eventTitle: PropTypes.string.isRequired,
    eventContent: PropTypes.string.isRequired,
    eventAddress: PropTypes.string.isRequired,
    eventTime: PropTypes.string.isRequired,
    eventNum: PropTypes.number.isRequired,
};