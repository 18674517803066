import React from 'react';
import '../css/nomatch.scss';

//Component for Home Page
const NoMatch = () => {
    //scroll to top
    window.scrollTo(0, 0);

    return <section id="nomatch">
                <div className="container">
                    <h1>404</h1>
                    <h3>К сожалению, такой страницы не существует, возможно, интересующая Вас информация находится на главной странице.</h3>
                </div>
            </section>
};


export default NoMatch;