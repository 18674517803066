import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {SubscribeForm} from '../components/SubscribeForm';
import {getInfo, fetchSubscribeForm} from '../actions/';
import LogoWhite from '../img/logo-white.svg';
import InstaIconWhite from '../img/insta-icon-white.svg';
import FbIconWhite from '../img/fb-icon-white.svg';
import YoutIconWhite from '../img/yout-icon-white.svg';
import '../css/footer.scss';
import * as t from '../constants';

//Container for app's footer
class Footer extends React.Component {
    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/pages/74', 'GET_FOOTER', 'GET_FOOTER_FAILURE');
    }

    handleSubmit = (values) => {
        this.props.fetchSubscribeForm(values);
    };

    render(){
        const {data, loader} = this.props;

        let footerTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            footerTemplate =  <footer>
                <p style={{textColor: '#fff'}}>{t.WAITING_FOR_FOOTER_LOAD}</p>
            </footer>

        } else if (data.acf) {

            const mailTo = 'mailto:' + t.ADMIN_EMAIL;

            footerTemplate = <footer>
                <div className="container">
                    <div className="colls-2">
                        <img src={LogoWhite} className="botlogo" alt=""/>
                        <ul className="botsoc">
                            <li><a href={t.INSTAGRAMM_LINK} target="_blank" rel="noopener noreferrer"><img src={InstaIconWhite} className="insta-icon" alt=""/></a></li>
                            <li><a href={t.FACEBOOK_LINK} target="_blank" rel="noopener noreferrer"><img src={FbIconWhite} className="insta-icon" alt=""/></a></li>
                            <li><a href={t.YOUTUBE_LINK} target="_blank" rel="noopener noreferrer"><img src={YoutIconWhite} className="insta-icon" alt=""/></a></li>
                        </ul>
                        <div className="clear" />

                        <a href={mailTo} className="botmail">{t.ADMIN_EMAIL}</a>

                        <a href={mailTo} className="white-btn">
                            {t.WRITE_TO_ME}
                        </a>

                        <div className="copy">
                            {data.acf.footer_copyright}
                        </div>
                    </div>
                    <div className="colls-2">
                        <div className="botform-text" dangerouslySetInnerHTML={{__html: data.acf.footer_subscribe}} />


                        <SubscribeForm onSubmit={this.handleSubmit} success={data.formSent} waiting={data.formWaiting} />


                        <div className="chg">
                            {t.DESIGNED_IN} <a href="http://cherry-garden.com/" target="_blank" rel="noopener noreferrer">{t.CHG}</a>
                        </div>
                    </div>
                    <div className="clear" />
                </div>
            </footer>

        } else {

            footerTemplate = '';

        }

        return footerTemplate;
    }
}


const mapStateToProps = store => {
    return store.footer;
};


const mapDispatchToProps = dispatch => {
    return {
        fetchSubscribeForm: values => dispatch(fetchSubscribeForm(values)),
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer)

Footer.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};