import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getInfo} from '../actions/';
import Slider from "react-slick";
import '../css/photos.scss';
import * as t from '../constants/';

//Container for photos section
class Photos extends React.Component {
    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/pages/130', 'GET_PHOTOS', 'GET_PHOTOS_FAILURE');
    }

    render(){

        const {data, loader} = this.props;

        //slick Slider
        let settings = {
            infinite: true,
            slidesToScroll: 1,
            variableWidth: true
        };


        let photosTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            photosTemplate =  <section id="photos">
                <div className="container">
                <p style={{textColor: '#fff'}}>{t.WAITING_FOR_PHOTOS_LOAD}</p>
                </div>
            </section>

        } else if (data.acf) {

            //get photos from gallery
            let photo = data.acf.photos.map( (item) => {
                return (
                    <div key={item.id}>
                        <img src={item.url} alt="" />
                    </div>
                )
            });

            photosTemplate =  <section id="photos">
                <div className="h1-wrapper">
                    <div className="container">
                        <h1>
                            {t.PHOTOS_TITLE}
                        </h1>
                    </div>
                </div>
                <Slider {...settings} className="photos">
                    {photo}
                </Slider>
            </section>
        } else {

            photosTemplate = '';

        }

        return photosTemplate;
    }
}


const mapStateToProps = store => {
    return store.photos;
};


const mapDispatchToProps = dispatch => {
    return {
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Photos)

Photos.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};