import React from 'react';
import Hello from "../containers/Hello";
import About from "../containers/About";
import Photos from "../containers/Photos";
import Interview from "../containers/Interview";
import EventsList from "../containers/Eventslist";
import ToursList from "../containers/Tourslist";

//Component for Home Page
const Home = () => {
    return(
        <React.Fragment>
            <Hello />
            <About />
            <Photos />
            <Interview />
            <EventsList />
            <ToursList />
        </React.Fragment>
    )
};


export default Home;