import React from 'react';
import PropTypes from 'prop-types';

//Component, that is showing each Event from Events section
export class PastEventsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventsYears: []
        }
    }

    componentDidMount() {
        let years = this.props.eventsList.map(item => {
            return {
                year: item.acf.event_date.split(' ', 1).toString(),
                active: false
            };
        });

        years.sort(function(a, b) {
            return a.year - b.year;
        });

        this.setState({
            eventsYears: years
        });
    }

    render() {
        const {sortEventsList} = this.props;

        //sorting list + change active param to true in state
        const sortList = (year, key) => {
            sortEventsList(year);

            let curState = this.state;

            curState.eventsYears.map(item => {
                return item.active = false;
            });

            curState.eventsYears[key].active = true;
            this.setState(curState);
        };

        return <div className="filterWrap">
            {this.state.eventsYears.map((item, key) => {
                return <div id="yearSort" className={item.active ? 'active' : null} key={key} onClick={() => sortList(item.year, key)}>{item.year}</div>;
            })}
        </div>;
    }
}

PastEventsFilter.propTypes = {
    sortEventsList: PropTypes.func.isRequired,
};