import React from 'react';
import PropTypes from 'prop-types';

//Component, that is showing publications slider in Interview section
class Publication extends React.Component {
    render () {

        const {publicationLink, publicationImage, publicationLogo, publicationTitle} = this.props;

        return (
            <div className="int-block-wrap">
                <div className="int-block">
                    <a href={publicationLink} target="_blank" rel="noopener noreferrer">
                        <div className="int-img">
                            <img src={publicationImage} alt="" />
                        </div>
                        <div className="int-text">
                            <div className="int-logo">
                                <img src={publicationLogo} alt="" />
                            </div>
                            <div dangerouslySetInnerHTML={{__html: publicationTitle}} />
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export {Publication}

Publication.propTypes = {
    publicationLink: PropTypes.string.isRequired,
    publicationImage: PropTypes.string.isRequired,
    publicationLogo: PropTypes.string.isRequired,
    publicationTitle: PropTypes.string.isRequired,
};