export const initialState = {
    data: {},
    loader: true
};



export function hello(state = initialState, action) {
    switch (action.type) {
        case 'GET_HELLO':
            return {
                ...state,
                data: action.payload,
                loader: false
            };

        case 'GET_HELLO_FAILURE':
            return {
                ...state,
                error: action.error,
                loader: false
            };

        default:
            return state
    }

}