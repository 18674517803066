import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Event} from '../components/Event';
import {getInfo} from '../actions/';
import '../css/events.scss';
import * as t from '../constants/';

//container for Events section
class EventsList extends React.Component {
    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/posts?categories=2', 'GET_EVENTS', 'GET_EVENTS_FAILURE');
    }

    render() {

        const {data, loader} = this.props.data;

        //Filter data by date to show only future events
        let futureEventsArray = [];
        if (data.length > 0) {
            futureEventsArray = data.filter( item => {
                return (new Date() < new Date(item.acf.event_date)) ? item : null;
            });
        }

        let eventsTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            eventsTemplate = <section id="events" name="#events">
                <div className="container">
                <h1>{t.EVENTS_TITLE}</h1>
                <div className="clear" />
                <p>{t.WAITING_FOR_EVENTS_LOAD}</p>
                <div className="clear" />
                </div>
            </section>

        } else if (futureEventsArray.length > 0) {  //if elements count is more, than 0: showing them

            eventsTemplate = <section id="events" name="#events">
                <div className="container">
                    <h1>{t.EVENTS_TITLE}</h1>

                    <Link className="past-events" to="/past_events">Прошедшие события</Link>

                <div className="clear" />
                { futureEventsArray.map( (item, i) => {
                    return (
                        <Event
                            eventDate={item.acf.event_date}
                            eventCity={item.acf.event_city}
                            eventPhoto={item.acf.event_photo}
                            eventTitle={item.title.rendered}
                            eventContent={item.content.rendered}
                            eventAddress={item.acf.event_address}
                            eventTime={item.acf.event_time}
                            key={item.id}
                            eventNum={i}
                        />
                    )
                }) }
                <div className="clear" />
                </div>
            </section>

        } else { //otherwise don't show this Section

            eventsTemplate = ''

        }

        return eventsTemplate;

    }
}


const mapStateToProps = store => {
    return {
        data: store.events,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventsList)

EventsList.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};