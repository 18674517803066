export const initialState = {
    data: [],
    loader: true,
    menuitem: true
};

export function events(state = initialState, action) {

    switch (action.type) {
        case 'GET_EVENTS':

            //add menuitem boolean to show correct menu at inner pages
            localStorage.setItem('events', JSON.stringify({menuitem: true}));

            //change state
            return {
                ...state,
                data: action.payload,
                loader: false,
                menuitem: true
            };

        case 'GET_EVENTS_FAILURE':

            //add menuitem boolean to show correct menu at inner pages
            localStorage.setItem('events', JSON.stringify({menuitem: false}));

            //change state
            return {
                ...state,
                data: [],
                error: action.error,
                loader: false,
                menuitem: false
            };

        default:
            return state
    }

}