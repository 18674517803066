import React from 'react';
import PropTypes from 'prop-types';

//Components, making parallax effect with mousemove
class ParallaxElement extends React.Component {

    render() {

        const {itemId, src, position} = this.props;

        const itemClass = 'main-block main-block' + itemId;
        const elPosition = position * 0.065 * ('0.' + itemId);

        return (
            <img src={src} className={itemClass} style={{right: elPosition}} alt="" />
        )
    }
}

class ParallaxElements extends React.Component {
    render() {

        const {elements, position} = this.props;

        return (
            <div className="parallaxWrap">
                {elements.map( (item)=> <ParallaxElement key={item.id} src={item.url} itemId={item.id} position={position} /> )}
            </div>
        )
    }
}

export {ParallaxElements}

ParallaxElements.propTypes = {
    elements: PropTypes.array.isRequired,
    position: PropTypes.number,
    itemId: PropTypes.number,
    src: PropTypes.string,
};