import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {ParallaxElements} from '../components/Parallax';
import {getInfo} from '../actions/';
import '../css/hello.scss';
import * as t from '../constants/';

//Container for first section of app
class Hello extends React.Component {
    constructor(props) {
        super(props);

        //state for parallax effect
        this.state = { x: 0 };
    }

    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/pages/195', 'GET_HELLO', 'GET_HELLO_FAILURE');
    }

    render(){

        const {data, loader} = this.props;

        //function for parallax effect
        const parallaxMove = (e) => {
            this.setState({ x: e.screenX });
        };

        let helloTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            helloTemplate =  <section id="firstscreen">
                <div className="container">
                    <p style={{textColor: '#fff', paddingTop: '80px', marginTop: '0'}}>{t.WAITING_FOR_LOAD}</p>
                </div>
            </section>

        } else if (data.acf) {

            const mailTo = 'mailto:' + t.ADMIN_EMAIL;

            const parallaxImagesArray = [
                {id: 2, url: 'https://katjakarling.com/wpcat/wp-content/uploads/2018/12/main-block2.svg'},
                {id: 3, url: 'https://katjakarling.com/wpcat/wp-content/uploads/2018/12/main-block3.svg'},
                {id: 4, url: 'https://katjakarling.com/wpcat/wp-content/uploads/2018/12/main-block4.png'},
            ];

            helloTemplate =  <section id="firstscreen" onMouseMove={parallaxMove.bind(this)}>
                <div className="container">
                    <div className="text-block">
                        <div dangerouslySetInnerHTML={{__html: data.content.rendered}} />

                        <div className="buts-block">
                            <a href={mailTo} className="black-btn">
                                {t.CONNECT}
                            </a>

                            <img src="https://katjakarling.com/wpcat/wp-content/uploads/2018/12/play-btn.svg" className="play-btn" alt="" />
                        </div>
                    </div>

                    <img src="https://katjakarling.com/wpcat/wp-content/uploads/2018/12/katja-main.png" className="katja-main" alt="" />
                        <ParallaxElements elements={parallaxImagesArray} position={this.state.x} />
                    <div className="clear" />
                </div>
            </section>

        } else {

            helloTemplate = '';

        }

        return helloTemplate;
    }
}


const mapStateToProps = store => {
    return store.hello;
};


const mapDispatchToProps = dispatch => {
    return {
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Hello)

Hello.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};