import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Tour} from '../components/Tour';
import {getInfo, fetchToursForm} from '../actions/';
import '../css/tours.scss';
import * as t from '../constants/';

//container for Tours section
class ToursList extends React.Component {
    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/posts?categories=3', 'GET_TOURS', 'GET_TOURS_FAILURE');
    }

    fetchToursFormFunc = (values) => {
        this.props.fetchToursForm(values);
    };

    render() {
        const {data, loader} = this.props.data;

        let toursTemplate;

        if (loader) { //If key loader is true, show "waiting for information", otherwise load info

            toursTemplate = <section id="tours" name="#tours">
                <div className="h1-wrapper">
                <div className="container">
                    <h1>{t.TOURS_TITLE}</h1>
                    <p style={{textColor: '#fff'}}>{t.WAITING_FOR_TOURS_LOAD}</p>
                </div>
                </div>
            </section>

        } else if (data.length > 0) {  //if elements count is more, than 0: showing them, otherwise show "There is no elements"

            let tour = data.map( (item) => {

                return (

                    <Tour
                        tourBg={item.acf.tour_bg}
                        tourGallery={item.acf.tour_gallery}
                        tourDays={item.acf.tour_days}
                        tourMonth={item.acf.tour_month}
                        tourLogo={item.acf.tour_logo}
                        tourIcons={item.acf.tour_icons}
                        tourAnnouncement={item.acf.tour_announcement}
                        tourMinilogo={item.acf.tour_minilogo}
                        tourLink={item.acf.tour_link}
                        tourContent={item.content.rendered}
                        tourId={item.id}
                        tourTitle={item.title.rendered}
                        fetchToursForm={this.fetchToursFormFunc}
                        key={item.id}
                    />
                )
            });

            toursTemplate = <section id="tours" name="#tours">
                <div className="h1-wrapper">
                    <div className="container">
                        <h1>{t.TOURS_TITLE}</h1>
                    </div>
                </div>
                {tour}
            </section>

        } else {

            toursTemplate = '';

        }

        return toursTemplate;
    }
}


const mapStateToProps = store => {
    return {
        data: store.tours
    };
};


const mapDispatchToProps = dispatch => {
    return {
        fetchToursForm: values => dispatch(fetchToursForm(values)),
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToursList)

ToursList.propTypes = {
    data: PropTypes.object,
    loader: PropTypes.bool,
};