import React from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { Publication } from '../components/Publication';
import {getInfo} from '../actions/';
import '../css/interview.scss';
import * as t from '../constants/';

//Container for Interview section
class Interview extends React.Component {
    componentDidMount() {
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/pages/87', 'GET_INTERVIEW', 'GET_INTERVIEW_FAILURE');
        this.props.getInfo('https://katjakarling.com/wpcat/wp-json/wp/v2/posts?categories=4', 'GET_PUBLICATIONS', 'GET_PUBLICATIONS_FAILURE');
    }

    render () {

        //slick Slider
        let settings = {
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };


        //template for "Interview and Publications"
        let interviewTemplate;

        if (this.props.interview.loader) { //If key loader is true, show "waiting for information", otherwise load info

            interviewTemplate =  <section id="interview" name="#interview">
                <div className="container">
                    <h1>{t.INTERVIEW_TITLE}</h1>
                    <p>{t.WAITING_FOR_INTERVIEW_LOAD}</p>
                </div>
            </section>

        } else if (this.props.interview.acf !== undefined) {

            //start of template for Publications slider
            let publicationsTemplate;

            if (this.props.publications.loader) { //If key loader is true, show "waiting for information", otherwise load info

                publicationsTemplate = <p>{t.WAITING_FOR_PUBLICATIONS_LOAD}</p>

            } else if (this.props.publications.length > 0) {  //if element's count more, than 0: show them, otherwise: show "there is no elements"

                let publication = this.props.publications.map( (item) => {

                    return (
                        <Publication
                            publicationLink={item.acf.public_link}
                            publicationImage={item.acf.public_image}
                            publicationLogo={item.acf.public_logo}
                            publicationTitle={item.title.rendered}
                            key={item.id}
                        />
                    )
                });

                publicationsTemplate = <Slider {...settings} className="interview">
                    {publication}
                </Slider>

            } else {

                publicationsTemplate = ''

            }
            //end of template for Publications slider


            //email link
            const mailTo = 'mailto:' + t.ADMIN_EMAIL;

            //full block with interview and publications
            interviewTemplate =  <section id="interview" name="#interview">
                <div className="container">
                    <h1>{t.INTERVIEW_TITLE}</h1>

                    {publicationsTemplate}

                    <div className="clear" />

                    <div className="leftblock">
                        <div className="tab">
                            <div className="cell cell1">
                                <iframe width="560" height="315" src={this.props.interview.acf.interview_video} title="Видео" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className="cell cell2">
                                <img src={this.props.interview.acf.interview_video_logo} className="logo-ort" alt="" />
                                <div className="text1" dangerouslySetInnerHTML={{__html: this.props.interview.acf.interview_video_title}} />
                                <div className="text2" dangerouslySetInnerHTML={{__html: this.props.interview.acf.interview_video_desc}} />
                            </div>
                        </div>
                    </div>

                    <div className="rightblock">
                        <div dangerouslySetInnerHTML={{__html: this.props.interview.acf.interview_youtube}} />

                        <p>
                            <a href={mailTo} className="black-btn" rel="noopener noreferrer">
                                {t.CONNECT_WITH_ME}
                            </a>
                        </p>
                    </div>
                    <div className="clear" />

                </div>
            </section>
        } else {

            interviewTemplate = '';

        }

        return interviewTemplate;
    }
}


const mapStateToProps = store => {
    return {
        interview: store.interview.data,
        publications: store.publications.data,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getInfo: (url, type_success, type_failure) => dispatch(getInfo(url, type_success, type_failure))
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Interview)