import { combineReducers } from 'redux';

import { navigation } from './Navigation';
import { hello } from './Hello';
import { about } from './About';
import { photos } from './Photos';
import { publications } from './Publications';
import { interview } from './Interview';
import { events } from './Events';
import { tours } from './Tours';
import { reducer as formReducer } from 'redux-form';
import { footer } from './Footer';

export const rootReducer = combineReducers({
    navigation,
    hello,
    about,
    photos,
    publications,
    interview,
    events,
    tours,
    footer,
    form: formReducer
});