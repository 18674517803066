export const initialState = {
    data: {},
    loader: true
};



export function photos(state = initialState, action) {
    switch (action.type) {
        case 'GET_PHOTOS':
            return {
                ...state,
                data: action.payload,
                loader: false
            };

        case 'GET_PHOTOS_FAILURE':
            return {
                ...state,
                error: action.error,
                loader: false
            };

        default:
            return state
    }

}